import * as React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Contact from "../components/Contact/Contact"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"

const contact = () => {
  return (
    <>
      <Seo title="Contact" />
      <Layout>
        <SimpleBanner title="Contact">
          {/* <StaticImage
            className="banner__image"
            src="../../static/macbook-dark.jpg"
            alt="Apple Macbook Dark"
          /> */}
        </SimpleBanner>
        <div
          className="gogoleForm"
          style={{ textAlign: "center", marginTop: "30px" }}
        >
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSe6oaYn5zJkbyw8XLm437UEJOQ9b7sRiiGvArbnoic1OhbC0w/viewform?embedded=true"
            width="640"
            height="1000"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            読み込んでいます…
          </iframe>
        </div>
      </Layout>
    </>
  )
}

export default contact
